
const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY

const colorShadow = insuranceCompany === 'OCEANICA' ? "rgba(127, 210, 203, 0.7)" : "rgba(245, 175, 0, 0.7)";
const centeredMargin = insuranceCompany === 'OCEANICA' ? "0" : "7.5px";
const width = insuranceCompany === 'OCEANICA' ? "80%" : "60%";

const useStyles = {
  container: {
    display: 'flex',
    width: "100vw",
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  "@keyframes EaseIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(-200%)"
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)"
    }
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: `0 0 0 0 ${colorShadow}`,
	    transform: "scale(0.80)"
    },
    "70%": {
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
	    transform: "scale(1)"
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
	    transform: "scale(0.80)"
    },
  },
  logo:{
    marginLeft: centeredMargin,
    width: width
  },
  logoContainer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    animation: `$pulse 2800ms infinite, $EaseIn 2000ms`,
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
	  transform: "scale(0.95)"
  }
}

export default useStyles
